import React, { useContext, useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import ReactStars from 'react-stars';
import { addDoc } from 'firebase/firestore';
import { moviesRef } from '../components/firebase/firebase';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { AppState } from '../App';
import { useFormik } from 'formik';
import { addNewSchema } from '../schema';

const initialValues = {
    name: "",
    year: "",
    image: "",
    description: "",
    videoid: ""
};

const AddMovie = () => {
    const useAppState = useContext(AppState);
    const navigate = useNavigate();
    const [ratings, setRatings] = useState(0);
    const [loading, setLoading] = useState(false);

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: addNewSchema,
        onSubmit: (values, action) => {
            addMovie();
            // action.resetForm();
        }
    });

    const addMovie = async () => {
        setLoading(true);
        try {
            await addDoc(moviesRef, {
                name: values.name,
                year: values.year,
                description: values.description,
                image: values.image,
                ratings: ratings,
                ratingcount: 1,
                videoid: values.videoid
            });
            swal({
                title: "Successfully Added",
                icon: "success",
                buttons: false,
                timer: 1000
            });
            setLoading(false);
            navigate("/");

        } catch (error) {
            swal({
                title: "Something went Wrong",
                icon: "error",
                buttons: false,
                timer: 1000
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!localStorage.getItem("UIDDI")) {
            navigate('/login');
        }
    }, [useAppState.login])

    return (
        <section className="max-w-4xl p-6 mx-auto rounded-md shadow-md m-auto ">
            <div className="border-b border-gray-900/10 pb-12">
                <form onSubmit={handleSubmit}>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-5">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-slate-300">Movie Title</label>
                            <div className="mt-2">
                                <input type="text" name="name" id="name" autoComplete="given-name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                            </div>
                            {errors.name && touched.name ? <p className='form-error'>{errors.name}</p> : null}
                        </div>

                        <div className="sm:col-span-1">
                            <label htmlFor="year" className="block text-sm font-medium leading-6 text-slate-300">Year</label>
                            <div className="mt-2">
                                <input type="text" name="year" id="year" autoComplete="address-level2"
                                    value={values.year}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                            </div>
                            {errors.year && touched.year ? <p className='form-error'>{errors.year}</p> : null}
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="image" className="block text-sm font-medium leading-6 text-slate-300">Image</label>
                            <div className="mt-2">
                                <input id="image" name="image"
                                    type="text"
                                    value={values.image}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                            </div>
                            {errors.image && touched.image ? <p className='form-error'>{errors.image}</p> : null}
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="videoid" className="block text-sm font-medium leading-6 text-slate-300">Youtube Video ID</label>
                            <div className="mt-2">
                                <input id="videoid" name="videoid"
                                    type="text"
                                    value={values.videoid}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                            </div>
                            {errors.videoid && touched.videoid ? <p className='form-error'>{errors.videoid}</p> : null}
                        </div>

                        <div className="sm:col-span-6">
                            <label className="block text-sm font-medium leading-6 text-slate-300" htmlFor="description" name="description">Movie Description</label>
                            <textarea
                                id="description" 
                                name="description"
                                rows="4"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2 pl-2"></textarea>
                            {errors.description && touched.description ? <p className='form-error'>{errors.description}</p> : null}
                        </div>

                        <div className="sm:col-span-2 flex items-center">
                            <label htmlFor="rating" className="block text-sm font-medium leading-6 text-slate-300">Rating</label>
                            <div className="ml-2">< ReactStars count={5} size={30} half={true} edit={true} value={ratings} onChange={(rate) => setRatings(rate)} /></div>
                        </div>
                    </div>
                    <div className='p-2 mt-16 flex justify-center'>
                        <button
                            type="submit"
                            className="flex mx-auto text-white bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                            {loading ? <TailSpin height={25} color="white" /> : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default AddMovie