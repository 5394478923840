import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAcKe14t6xVLgtrY9TU87qp9XbBZSqWqcI",
  authDomain: "filmvilla.firebaseapp.com",
  projectId: "filmvilla",
  storageBucket: "filmvilla.appspot.com",
  messagingSenderId: "8050047008",
  appId: "1:8050047008:web:a1606a370379c82ca56445"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const moviesRef = collection(db, "movies");
export const reviewsRef = collection(db, "reviews");
export const usersRef = collection(db, "users");

export default app;
