import React, { useContext, useEffect, useState } from 'react'
import { reviewsRef, db } from './firebase/firebase';
import ReactStars from 'react-stars';
import { addDoc, doc, updateDoc, where, getDocs, query } from 'firebase/firestore';
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import swal from 'sweetalert';
import { AppState } from '../App';
import { useNavigate } from 'react-router-dom';

const Reviews = ({ id, ratingcount, prevrating }) => {
    const useAppState = useContext(AppState);
    const navigate = useNavigate();
    const [rating, setRating] = useState(0);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [reviewLoad, setReviewLoad] = useState(false);
    const [data, setData] = useState([]);
    const [newReview, setNewReview] = useState(0);

    const submitReviews = async () => {
        if (useAppState.login) {
            setLoading(true);
            try {
                await addDoc(reviewsRef, {
                    movieId: id,
                    comment: comment,
                    name: useAppState.username,
                    rating: rating,
                    timestamp: new Date().getTime()
                });

                const docRef = doc(db, "movies", id);
                await updateDoc(docRef, {
                    ratings: prevrating + rating,
                    ratingcount: ratingcount + 1
                });

                swal({
                    title: "Review Submitted",
                    icon: "success",
                    buttons: false,
                    timer: 1000
                });
                setRating(0);
                setComment("");
                setNewReview(newReview + 1);
            } catch (error) {
                swal({
                    title: "Something went wrong",
                    icon: "error",
                    buttons: false,
                    timer: 1000
                });
            }
            setLoading(false);
        } else {
            navigate('/login');
        }
    }

    useEffect(() => {
        async function getData() {
            setReviewLoad(true);
            setData([]);
            let fetchQuery = query(reviewsRef, where('movieId', "==", id));
            const response = await getDocs(fetchQuery);

            response.forEach((item) => {
                setData((prev) => [...prev, item.data()]);
            })
            setReviewLoad(false);
        }
        getData();
    }, [newReview])

    return (
        <div className='reviw-section mt-2'>
            <ReactStars size={35} half={true} value={rating} onChange={(rate) => setRating(rate)} />
            <input type="text" className='w-full bg-gray-900 h-10 p-2 rounded' value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Share your openion..' />
            <button type='button' onClick={submitReviews} className='bg-green-600 p-2 rounded-full w-36 hover:bg-green-700 mt-2 flex justify-center'>
                {loading ? <TailSpin height={25} color="white" /> : "Publish"}
            </button>
            {
                reviewLoad ?
                    <div className='mt-6 flex justify-center'>
                        <ThreeDots height={35} color='white' />
                    </div>
                    :
                    <div className='mt-3'>
                        {
                            data.map((item, i) => {
                                return (
                                    <div className='p-4 w-full bg-gray-900 border-b bg-opacity-50 border-gray-600 mt-2' key={i}>
                                        <div className='flex items-center'>
                                            <p className='text-blue-500'>{item.name}</p>
                                            <p className='ml-6 text-xs'>({new Date(item.timestamp).toLocaleString()})</p>
                                        </div>
                                        <ReactStars
                                            size={15}
                                            half={true}
                                            value={item.rating}
                                            edit={false}
                                        />

                                        <p>{item.comment}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}

export default Reviews