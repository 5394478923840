import * as YUP from "yup";

export const signupSchema = YUP.object({
    name: YUP.string().min(3).max(30).required("Please enter your name"),
    password: YUP.string().min(8).required("Please enter your password"),
    confirm_password: YUP.string().required().oneOf([YUP.ref('password'), null], "Password must match"),
})

export const loginSchema = YUP.object({
    password: YUP.string().min(8).required("Please enter your password"),
})

export const addNewSchema = YUP.object({
    name: YUP.string().min(2).required("Please enter the movie name"),
    year: YUP.string().min(4).max(4).required("Please enter the movie year"),
    image: YUP.string().min(10).required("Please enter the image url"),
    videoid: YUP.string().min(8).required("Please enter the video ID"),
    description: YUP.string().min(100).required("Please enter the descrition"),
})