import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactStars from 'react-stars';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { Button } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../components/firebase/firebase';
import { Triangle } from 'react-loader-spinner';
import Reviews from './Reviews';
import './style.css';
import YouTube from 'react-youtube';

const Details = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [video, setVideo] = useState("none");
    const [vId, setVId] = useState("");
    const [data, setData] = useState({
        name: "",
        description: "",
        image: "",
        year: "",
        ratings: null,
        ratingcount: null,
        videoid: ""
    });

    const videoId = data.videoid;

    const videostyle = {
        display: video
    }

    const opts = {
        height: '400',
        width: '800',
        playerVars: {
            autoplay: 1,
        },
    };

    function playerOnReady(event) {
        const player = event.target;
        player.pauseVideo();
    }

    function handleVideoShow() {
        setVideo("block");
        setVId(videoId);
    }

    function handleVideo() {
        setVideo("none");
        setVId("");
    }

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const _doc = doc(db, "movies", id);
            const _data = await getDoc(_doc);
            setData(_data.data());
            setLoading(false);
        }
        getData();
    }, []);

    return (<>
        {loading ?
            <div className='w-full flex justify-center items-center align-middle m-auto h-[70vh]'>< Triangle height={90} width={90} visible={true} color='#CC3333' /></div >
            :
            <>
                <div className='app__moovie-details p-10 flex max-[786px]:flex-col justify-center'>
                    <img className='md:sticky top-28 w-[26rem] h-[600px] mr-10 max-[786px]:m-auto' src={data.image} alt="cover-logo" />
                    <div className='w-3/5 max-[786px]:w-full'>
                        <div className='subpixel-antialiased'>
                            <h1 className='max-[786px]:text-center text-[42px] mb-6 font-bold bg-gradient-to-r from-red-500 to-rose-400 bg-clip-text text-transparent'>{data.name}</h1>
                            <div className='description-section'>
                                <h1 className='text-xl font-medium'>Description</h1>
                                <p className='text-gray-400'>{data.description}</p>
                            </div>
                            <div className='rating-section mt-5'>
                                <span className='text-xl font-medium flex items-center'>Rating <span className='ml-2'><ReactStars size={25} half={true} count={5} edit={false} value={data.ratings / data.ratingcount} /></span></span>
                            </div>
                            <div className='year-section mt-5'>
                                <span className='text-xl font-medium flex items-center'>Year: <span className='ml-2 text-gray-400'>{data.year}</span></span>
                            </div>
                            <div className='watch-section mt-5 w-fit rounded-full'>
                                <Button varient="contained" color="secondary" onClick={()=> handleVideoShow()}><LiveTvIcon className='mr-2' color='secondary' /> <span className='text-white'>Watch</span></Button>
                            </div>
                            <div className='review-section mt-8'>
                                <span className='text-xl font-medium flex items-center'>Reviews</span>
                                <Reviews id={id} ratingcount={data.ratingcount} prevrating={data.ratings} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='video-bucket' style={videostyle} onClick={()=> handleVideo()}>
                    <div className='video-container z-[1000] w-full h-full m-auto justify-center bg-black opacity-80 absolute top-0 left-0'>
                    </div>
                    <div className='video-container-content z-[1500] justify-center fixed m-auto align-middle items-center opacity-100 bg-black top-[30%] left-[20%]'>
                        <YouTube
                            videoId={vId}
                            opts={opts}
                            onReady={playerOnReady}
                        />
                    </div>
                </div>
            </>
        }
    </>
    )
}

export default Details