import React, { useEffect, useState } from 'react';
import ReactStars from 'react-stars';
import { getDocs } from 'firebase/firestore';
import { moviesRef } from '../components/firebase/firebase';
import { Triangle } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import './cardstyle.css';

const Cards = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const _data = await getDocs(moviesRef);
            _data && setData([]);
            _data.forEach((doc) => {
                setData((prev) => [...prev, { ...(doc.data()), id: doc.id }]);
            })
            setLoading(false);
        }
        getData();

        setData(data);
    }, []);

    return (
        <div className='card-container flex flex-wrap justify-start p-3 mx-8 my-4 gap-10'>
            {loading ? <div className='w-full flex justify-center items-center align-middle m-auto h-[70vh]'><Triangle height={90} width={90} visible={true} color='#CC3333' /></div> :
                data.map((item) => (
                    <Link to={`/details/${item.id}`} key={item.id}>
                        <div
                            className='card-container-contents p-2 bg-slate-800 rounded shadow-lg hover:-translate-y-2 cursor-pointer transition-all duration-500 w-56'
                            key={item.id+item.name}
                        >
                            <img className='h-72 w-full mb-1' src={item.image} alt="movie banner" />
                            <h1 className='truncate ...'><span className='text-gray-400'></span>{item.name}</h1>
                            <h1 className='flex items-center'><span className='text-gray-400 mr-1'>Rating: </span>
                                < ReactStars size={20} half={true} count={5} edit={false} value={item.ratings / item.ratingcount} /></h1>
                            <h1><span className='text-gray-400'>Year: </span>{item.year}</h1>
                        </div>
                    </Link>
                ))
            }
        </div>
    )
}

export default Cards