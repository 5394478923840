import React, { useContext, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { query, where, getDocs } from 'firebase/firestore';
import swal from 'sweetalert';
import bcrypt from 'bcryptjs';
import { AppState } from '../App';
import { usersRef } from './firebase/firebase';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import bgMovie2 from '../assets/images/movie2.jpg';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import { loginSchema } from '../schema';

const initialValues = {
    password: ""
};

const Login = () => {
    const navigate = useNavigate();
    const useAppState = useContext(AppState);
    const [mobile, setMobile] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, action) => {
            login();
            // action.resetForm();
        }
    });

    const myStyle = {
        backgroundImage: `url(${bgMovie2})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'unset'
    };

    const login = async () => {
        if(!mobile) {
            return;
        }
        setLoading(true);
        try {
            const quer = query(usersRef, where('mobile', '==', mobile));
            const querySnapsot = await getDocs(quer);

            querySnapsot.forEach((doc) => {
                const _data = doc.data();
                const isUser = bcrypt.compareSync(values.password, _data.password);
                const salt = bcrypt.genSaltSync(10);
                var hashCode = bcrypt.hashSync("User-loggeD-in", salt);
                if (isUser) {
                    useAppState.setLogin(true);
                    localStorage.setItem("UIDDI", hashCode)
                    useAppState.setUserName(_data.name);
                    swal({
                        title: "Logged In",
                        icon: "success",
                        buttons: false,
                        timer: 1000
                    })
                    navigate('/')
                } else {
                    swal({
                        title: "Invalid Credentials",
                        icon: "error",
                        buttons: false,
                        timer: 1000
                    })
                }
            })
        } catch (error) {
            swal({
                title: error.message,
                icon: "error",
                buttons: false,
                timer: 1000
            })
        }
        setLoading(false);
    }

    return (
        <div className='flex flex-col item-center h-[90vh]' style={myStyle}>
            <section className="max-w-4xl min-[625px]:w-96 m-auto rounded-md">
                <form onSubmit={handleSubmit}>
                    <div className="pb-12">
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-slate-300">Phone Number</label>
                                <PhoneInput
                                    id="phone"
                                    country={"in"}
                                    value={mobile}
                                    onChange={setMobile}
                                    className="text-black mt-2"
                                />
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-slate-300">Password</label>
                                <div className="mt-2 flex items-center">
                                    <input
                                        id="password"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                                    <button onClick={() => setShowPassword(!showPassword)} className='text-black relative right-8 bg-white'>
                                        <VisibilityOffIcon />
                                    </button>
                                </div>
                                {errors.password && touched.password ? <p className='form-error'>{errors.password}</p> : null}
                            </div>

                        </div>
                        <div className='mt-3 p-2 flex flex-col items-center justify-center'>
                            <button
                                type='submit'
                                className="flex mx-auto text-white bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                {loading ? <TailSpin height={25} color="white" /> : 'Login'}
                            </button>
                            <p className='mt-3'>Do not have account? <Link to={'/signup'}><span className='text-black font-medium cursor-pointer '>Sign Up</span></Link></p>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default Login