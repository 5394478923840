import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { addDoc } from "firebase/firestore";
import { usersRef } from '../components/firebase/firebase';
import { auth } from '../components/firebase/firebase';
import bcrypt from 'bcryptjs';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from 'react-otp-input';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import bgMovie2 from '../assets/images/movie2.jpg';
import { useFormik } from 'formik';
import { signupSchema } from '../schema';

const initialValues = {
    name: "",
    password: "",
    confirm_password: ""
};

const Signup = () => {
    const navigate = useNavigate();
    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: signupSchema,
        onSubmit: (values, action) => {
            onSignup();
        }
    });

    const [mobile, setMobile] = useState("");
    const [loading, setLoading] = useState(false);
    const [otpSent, SetOtpSent] = useState(false);
    const [OTP, setOTP] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const myStyle = {
        backgroundImage: `url(${bgMovie2})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'unset'
    };

    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        onSignup();
                    },
                    "expired-callback": () => { },
                }, auth
            );
        }
    }

    function onSignup() {
        if(!mobile) {
            return;
        }
        setLoading(true);
        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, `+${mobile}`, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                swal({
                    text: "OTP Sent",
                    icon: "success",
                    buttons: false,
                    timer: 1000,
                });
                SetOtpSent(true);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    function onOTPVerify() {
        try {
            setLoading(true);
            window.confirmationResult.
                confirm(OTP)
                .then((result) => {
                    uploadData();
                    swal({
                        text: "Sucessfully Registered",
                        icon: "success",
                        buttons: false,
                        timer: 1000,
                    });
                    setLoading(false);
                    navigate('/login');
                })
        } catch (error) {
            console.log(error);
        }
    }

    const uploadData = async () => {
        try {
            const salt = bcrypt.genSaltSync(10);
            var hash = bcrypt.hashSync(values.password, salt);
            await addDoc(usersRef, {
                name: values.name,
                password: hash,
                mobile: mobile
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='flex flex-col item-center h-[90vh]' style={myStyle}>
            {
                otpSent ?
                    <section className="max-w-4xl min-[625px]:w-96 mx-auto rounded-md m-auto ">
                        <div className="pb-12">
                            <div className="mt-6 flex justify-center">
                                <div className="sm:col-span-6">
                                    <label htmlFor="otp" className="block text-sm font-medium leading-6 text-slate-300"> Enter your OTP</label>
                                    <div className="mt-2 otp-container">
                                        <OtpInput
                                            value={OTP}
                                            onChange={setOTP}
                                            numInputs={6}
                                            inputType={"tel"}
                                            disabled={false}
                                            shouldAutoFocus={true}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3 p-2 flex flex-col items-center justify-center'>
                                <button
                                    onClick={onOTPVerify}
                                    className="flex mx-auto text-white bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                    {loading ? <TailSpin height={25} color="white" /> : 'Confirm OTP'}
                                </button>
                                <p className='mt-3'>Already have account? <Link to={'/login'}><span className='text-blue-500 cursor-pointer'>Login</span></Link></p>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="max-w-4xl min-[625px]:w-96 mx-auto rounded-md">
                        <div id='recaptcha-container'></div>
                        <form onSubmit={handleSubmit}>
                            <div className="pb-12">
                                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-slate-300">Name</label>
                                        <div className="mt-2">
                                            <input
                                                id="name"
                                                name="name"
                                                type='text'
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                                        </div>
                                        {errors.name && touched.name ? <p className='form-error'>{errors.name}</p> : null}
                                    </div>
                                    <div className="sm:col-span-6">
                                        <label htmlFor="phone" className="block text-sm font-medium leading-6 text-slate-300">Phone Number</label>
                                        <div className="mt-2">
                                            <PhoneInput
                                                id="phone"
                                                name="phone"
                                                country={"in"}
                                                value={mobile}
                                                onChange={setMobile}
                                                className="text-black mt-2"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-6">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-slate-300">Password</label>
                                        <div className="mt-2 flex items-center">
                                            <input
                                                id="password"
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                                            <button onClick={() => setShowPassword(!showPassword)} className='text-black relative right-8 bg-white'>
                                                <VisibilityOffIcon />
                                            </button>
                                        </div>
                                        {errors.password && touched.password ? <p className='form-error'>{errors.password}</p> : null}
                                    </div>

                                    <div className="sm:col-span-6">
                                        <label htmlFor="confirm_password" className="block text-sm font-medium leading-6 text-slate-300">Confirm Password</label>
                                        <div className="mt-2 flex items-center">
                                            <input
                                                id="confirm_password"
                                                name="confirm_password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.confirm_password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2" />
                                            <button onClick={() => setShowPassword(!showPassword)} className='text-black relative right-8 bg-white'>
                                                <VisibilityOffIcon />
                                            </button>
                                        </div>
                                        {errors.confirm_password && touched.confirm_password ? <p className='form-error'>{errors.confirm_password}</p> : null}
                                    </div>

                                </div>
                                <div className='mt-3 p-2 flex flex-col items-center justify-center'>
                                    <button
                                        type='submit'
                                        className="flex mx-auto text-white bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                        {loading ? <TailSpin height={25} color="white" /> : 'Request OTP'}
                                    </button>
                                    <p className='mt-3'>Already have account? <Link to={'/login'}><span className='text-black font-medium cursor-pointer'>Login</span></Link></p>
                                </div>
                            </div>
                        </form>
                    </section>
            }
        </div>
    )
}

export default Signup