import Header from './components/Header';
import Cards from './components/Cards';
import { Routes, Route } from 'react-router-dom';
import AddMovie from './components/AddMovie';
import Details from './components/Details';
import { createContext, useState, useEffect } from 'react';
import Login from './components/Login';
import Signup from './components/Signup';
import bcrypt from 'bcryptjs';

const AppState = createContext();

function App() {
  const [login, setLogin] = useState(false);
  const [username, setUserName] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("UIDDI");
    if (user) {
      const isUser = bcrypt.compareSync("User-loggeD-in", user);
      if (isUser) {
        setLogin(true);
      }
    }
  }, [])

  return (
    <AppState.Provider value={{ login, username, setLogin, setUserName }}>
      <div className='App relative'>
        <Header />
        <Routes >
          <Route path='/' element={<Cards />} />
          <Route path='/addnew' element={<AddMovie />} />
          <Route path='/details/:id' element={<Details />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
        </Routes>
      </div>
    </AppState.Provider>
  );
}

export default App;
export { AppState };